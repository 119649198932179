// Stores
import entityStore from './entityStore';
import appStore from './appStore';
import relationshipStore from './relationshipStore';
import loopStore from './loopStore';
import { enableStaticRendering } from 'mobx-react';

const isServer = typeof window === 'undefined';
enableStaticRendering(isServer);

export const stores = {
	entityStore,
	appStore,
	loopStore,
	relationshipStore,
};
