// Dependencies
import { makeAutoObservable } from 'mobx';
import Relationship from '../types/relationship';

/**
 * Used to detect if two relationships are the same. Returns true if they are,
 * otherwise false.
 *
 * @param {*} firstRelationship
 * @param {*} secondRelationship
 * @returns Boolean
 */
const exactFromAndTo = (firstRelationship, secondRelationship) => {
	return (
		firstRelationship.from.entityId === secondRelationship.from.entityId &&
		firstRelationship.to.entityId === secondRelationship.to.entityId
	);
};

class RelationshipStore {
	loading = true;
	error = null;
	relationships: Relationship[] = [];
	selected = null;
	from = null;
	to = null;

	constructor() {
		makeAutoObservable(this);
	}

	isADuplicate(relationship) {
		return this.relationships.some((r) => exactFromAndTo(r, relationship));
	}

	add(relationship) {
		if (!this.isADuplicate(relationship)) {
			this.relationships.push(relationship);
		}
		this.from = null;
		this.to = null;
	}

	select(id) {
		this.selected = id;
	}

	get(id) {
		return this.relationships.find((r) => r.id === id);
	}

	toggleType(id) {
		const relationship = this.get(id);
		if (relationship) {
			relationship.type =
			relationship.type === 'positive' ? 'negative' : 'positive';
		}
	}

	deselect() {
		this.selected = null;
	}

	remove(id) {
		this.relationships = this.relationships.filter((r) => r.id !== id);
	}
}

export default new RelationshipStore();
