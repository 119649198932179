// Dependencies
import { makeAutoObservable } from 'mobx';
import { Entity, Loop, Relationship } from '../types';

class LoopStore {
	loading = true;
	error = null;
	loops:Loop[] = [];
	reviewedRelationships:Relationship[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	detectEntitiesInLoop(path, relationships) {
		const entities: Entity[] = [];
		relationships
			.filter((r) => path.includes(r.id))
			.forEach((relationship) => {
				if (!entities.includes(relationship.from.entityId))
					entities.push(relationship.from.entityId);
				if (!entities.includes(relationship.to.entityId))
					entities.push(relationship.to.entityId);
			});
		return entities;
	}

	// TODO - evaluate the logic of this function
	detectLoopType(path, relationships) {
		const negativeAmount = relationships
			.filter((r) => path.includes(r.id))
			.filter((r) => r.type === 'negative').length;

		// Ok, need to check the logic - because if you have 2 negative and 2 positive, then isn't it a balancing loop?
		if (negativeAmount === 0 || negativeAmount % 2 === 0) {
			return 'reinforcing';
		} else {
			return 'balancing';
		}
	}

	addLoop({ chain, relationships }) {
		const path = chain.map((r) => r.id);
		const type = this.detectLoopType(path, relationships);
		const entities = this.detectEntitiesInLoop(path, relationships);
		this.loops.push({
			relations: path,
			type,
			entities,
		});
	}

	// TODO This logic needs reviewing
	findNextInChain({
		startRelationship,
		endRelationship,
		chain,
		relationships,
	}) {
		const { id, to } = chain[chain.length - 1];
		const isInLoop = this.findExistingLoop(id);
		// If it is already in a loop, then we don't bother looking at it
		if (isInLoop !== undefined) {
			return null;
		}

		// Here we look for relationships branching off from the entity
		const nextInTheChain = relationships.filter(
			(r) => r.from.entityId === to.entityId
		);
		// If none found, then that is it. We have reached the end of the chain
		if (nextInTheChain.length === 0) return null;
		// I think that this is the problem
		nextInTheChain.forEach((nr) => {
			if (this.findExistingLoop(nr.id) === undefined) {
				// Ok, so this chain just gets added to infinitely - it never completes
				// I think that it needs to be added to an already reviewed list to prevent infinite recursion
				if (!this.reviewedRelationships.includes(nr.id)) {
					chain.push(nr);
					this.reviewedRelationships.push(nr.id);
					// Found the last link in the loop
					if (nr.id === endRelationship.id) {
						this.addLoop({ chain, relationships });
					} else {
						this.findNextInChain({
							startRelationship,
							endRelationship,
							chain,
							relationships,
						});
					}
				}
			}
		});
	}

	findLoopIfExists({ startRelationship, endRelationship, relationships }) {
		const chain:Relationship[] = [];
		chain.push(startRelationship);
		return this.findNextInChain({
			startRelationship,
			endRelationship,
			chain,
			relationships,
		});
	}

	findExistingLoop(relationshipId) {
		// I reckon that the issue is here
		const findCondition = (l) => l.relations.includes(relationshipId);
		const foundExistingLoop = this.loops.find(findCondition);
		return foundExistingLoop;
	}

	getLinkedEntities(relationships) {
		const fromList:string[] = relationships.map((r) => r.from.entityId);
		const toList:string[] = relationships.map((r) => r.to.entityId);
		const linkedList = [
			...new Set(fromList.filter((f) => toList.includes(f))),
		];
		return linkedList;
	}

	/* 
		Check if there are no linked entities
		If there are no linked entities, then there are no loops
	*/
	linkedEntitiesExist(relationships) {
		const linkedList = this.getLinkedEntities(relationships);
		return linkedList.length >= 2;
	}

	getStartAndEndRelationships({ relationships, entityId }) {
		const startFilter = (r) => r.from.entityId === entityId;
		const endFilter = (r) => r.to.entityId === entityId;
		const startRelationships = relationships.filter(startFilter);
		const endRelationships = relationships.filter(endFilter);
		return { startRelationships, endRelationships };
	}

	loopThroughLinkedEntity({ relationships }) {
		const notAlreadyInALoop = (rId) => !this.findExistingLoop(rId);
		return (entityId) => {
			const {
				startRelationships,
				endRelationships,
			} = this.getStartAndEndRelationships({ relationships, entityId });
			startRelationships.forEach((sr) => {
				if (notAlreadyInALoop(sr.id)) {
					endRelationships.forEach((er) => {
						if (notAlreadyInALoop(er.id)) {
							this.findLoopIfExists({
								startRelationship: sr,
								endRelationship: er,
								relationships,
							});
						}
					});
				}
			});
		};
	}

	loopThroughLinkedEntities({ relationships }) {
		const linkedList = this.getLinkedEntities(relationships);
		linkedList.forEach(this.loopThroughLinkedEntity({ relationships }));
	}

	detectLoops(relationships) {
		this.loops = [];
		this.reviewedRelationships = [];
		const loopsPossiblyExist = this.linkedEntitiesExist(relationships);
		if (!loopsPossiblyExist) return;
		this.loopThroughLinkedEntities({ relationships });
	}
}

export default new LoopStore();
