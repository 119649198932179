// Dependencies
import { makeAutoObservable } from 'mobx';
import Entity from '../types/entity';

class EntityStore {
	loading = true;
	error = null;
	entities:Entity[] = [];
	selected = null;

	constructor() {
		makeAutoObservable(this);
	}

	add(entity) {
		this.entities.push(entity);
	}

	select(id) {
		this.selected = id;
	}

	get(id) {
		return this.entities.find((e) => e.id === id);
	}

	updateCoordinates(id, x, y) {
		const entity = this.entities.find((e) => e.id === id);
		if (entity?.x) {
			entity.x = x;
			entity.y = y;
		}
	}

	update(props) {
		const { id, text } = props;
		const entity = this.entities.find((e) => e.id === id);
		if (text && entity?.x) {
			const oldWidth = entity.width;
			entity.text = text;
			entity.width = 100 + text.length * 7;
			entity.x = entity.x - (entity.width - oldWidth) / 2;
		}
	}

	deselect() {
		this.selected = null;
	}

	remove(id) {
		this.entities = this.entities.filter((e) => e.id !== id);
	}
}

export default new EntityStore();
