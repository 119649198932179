import React, { Fragment } from 'react';
import App from 'next/app';
import Head from 'next/head';
import { Provider, observer } from 'mobx-react';

// Components

// Data

// Stores
import { stores } from '../src/stores';

// Styling
import '../styles/index.scss';

class MyApp extends App {
	render() {
		const { Component, pageProps } = this.props;
		return (
			<Provider {...stores}>
				<Fragment>
					<Head>
						<meta
							name="viewport"
							content="width=device-width, initial-scale=1"
						/>
						<meta
							name="keywords"
							content="Causal loop diagrams, anephenix"
						/>
						<meta
							name="description"
							content="An app for causal loop diagrams, from Anephenix"
						/>
					</Head>
					<Component {...pageProps} />
				</Fragment>
			</Provider>
		);
	}
}

export default observer(MyApp);
