// Dependencies
import { makeAutoObservable } from 'mobx';

class AppStore {
	mode = 'entity'; // entity - add entities - relationship - add relationships
	disableKeyboardShortcuts = false;
	mousePosition = { x: 0, y: 0 };
	transform = { html: '', svg: '', scale: 1, translateX: 0, translateY: 0 };
	isCircleDragging = false;
	dragPositionStart = { x: 0, y: 0 };
	dragPositionEnd = { x: 0, y: 0 };
	circleSide = null;

	constructor() {
		makeAutoObservable(this);
	}

	calculateXYCoords(xCoord, yCoord) {
		const { transform } = this;
		const scale = transform.scale || 1;
		const translateX = transform.translateX || 0;
		const translateY = transform.translateY || 0;
		const x = xCoord / scale - translateX / scale;
		const y = yCoord / scale - translateY / scale;
		return { x, y };
	}

	update(key, value) {
		this[key] = value;
	}

	save({ entities, relationships }) {
		const diagram = {
			name: 'Diagram 1',
			entities,
			relationships,
			transform: this.transform,
		};
		localStorage.setItem('diagram', JSON.stringify(diagram));
	}

	load() {
		const diagramItem = localStorage.getItem('diagram');
		if (diagramItem) {
			const diagram = JSON.parse(diagramItem);
			// NOTE - might be an idea to set this in pan and zoom in some way
			this.transform = diagram.transform;
			return diagram;

		}
	}
}

export default new AppStore();
